// import {wrapRootElement as wrap} from "./root-wrapper";
// const React = require('react');
// const Layout = require('./src/components/layout').default;
// does not work full page height at the start
// export const wrapRootElement = wrap;

export const onClientEntry = () => {
  window.addEventListener('load', () => {
    document.body.classList.add('body--loaded');
  });
};

// does not work animation with body-loaded
// export const wrapPageElement = ({ element, props}) => {
//   return <Layout {...props}>{element}</Layout>
// };
